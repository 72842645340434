<template>
  <div>
    <div class="search">
      <div class="title">秒杀日志列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="类型:">
          <el-select v-model="params.logType" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="昵称:">
          <el-input v-model="params.nickName"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions0"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
   
  
     
      <el-table-column prop="logId" label="ID" width="50" align="center">
      </el-table-column>
      <el-table-column prop="addTime" label="修改时间"  align="center">
      </el-table-column>
    
      <el-table-column prop="masterName" label="修改人"  align="center">
      </el-table-column>
      <el-table-column prop="updateType" label="修改类型" align="center">
      </el-table-column>
  
      <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
          
            <el-button type="primary"  size="mini" @click="edit(scope.row)">查看详情</el-button>
         
        </template>
        </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="分类名称:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
        <el-form-item label="分类图片:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { logList, } from "../../../api/seckillInfo.js";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        seckillId: 0,
        logType: -1,
      },
      options: [
        {
          label: "全部",
          value: -1,
        },
        {
          label: "删除",
          value: 0,
        },
        {
          label: "新增",
          value: 1,
        },
        {
          label: "名称",
          value: 2,
        },
        {
          label: "活动开始时间",
          value: 3,
        },
        {
          label: "活动结束时间",
          value: 4,
        },
        {
          label: "使用开始时间",
          value: 5,
        },
        {
          label: "使用结束时间",
          value: 6,
        },
        {
          label: "库存",
          value: 7,
        },
        {
          label: "商品ID",
          value: 8,
        },
        {
          label: "标签",
          value: 9,
        },
        {
          label: "状态",
          value: 10,
        },
        {
          label: "是否可退货",
          value: 11,
        },
        {
          label: "秒杀价格",
          value: 12,
        },
        {
          label: "原价",
          value: 13,
        },
        {
          label: "限购",
          value: 14,
        },
        {
          label: "排序",
          value: 15,
        },
        {
          label: "活动图片",
          value: 16,
        },
        {
          label: "初始库存",
          value: 17,
        },
        {
          label: "活动说明",
          value: 18,
        },
        {
          label: "游戏ID",
          value: 19,
        },
        {
          label: "是否改期",
          value: 20,
        },
        {
          label: "是否显示",
          value: 21,
        },
        {
          label: "提前预约天数",
          value: 22,
        },
        {
          label: "分享标题",
          value: 23,
        },
        {
          label: "万旅网是否显示",
          value: 24,
        },
        {
          label: "价格/库存",
          value: 25,
        },
        {
          label: "升级添加",
          value: 26,
        },
        {
          label: "升级删除",
          value: 27,
        },
        {
          label: "升级价格",
          value: 28,
        },
      ],
      addList: {
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      
      addRules: {
        tableData: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.params.seckillId = this.$route.query.id
    this.getList();
  },
  methods: {
    typeHandel(row){
        let index = this.options.findIndex(item=>item.value==item.updateType)
        return this.options[index].label
    },
    async edit(row){
       this.$router.push('/seckillLogDetails?id='+row.logId)
   
    },
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await logList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>